<template>
  <div class="d-flex">
    <b-button
      @click="confirmRoundDeletion"
      :disabled="minRoundsToKeep >= value"
      variant="outline-secondary"
      v-if="canRemoveRound"
    >
      -
    </b-button>
    <div class="value btn-outline-secondary"><span>{{ value }}</span></div>
    <b-button
      @click="addRound"
      :disabled="maxRounds != null && maxRounds <= value"
      variant="outline-secondary"
      v-if="canAddRound"
    >
      +
    </b-button>

    <b-modal
      v-model="showDeletionConfirmationDialog"
      title="Confirmation"
      @ok="removeRound"
      ok-variant="warning"
      ok-title="Oui, supprimer 1 manche"
      cancel-variant="secondary"
      cancel-title="Non"
    >
      <p><slot name="confirmationDialogText">Etes-vous sûr de vouloir supprimer une manche ?</slot></p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'NumberOfRoundsEditor',
  props: {
    value: { type: Number, required: true},
    minRoundsToKeep: { type: Number, default: 0},
    maxRounds: { type: Number, default: null},
    canRemoveRound: { type: Boolean, default: true},
    canAddRound: { type: Boolean, default: true},
    confirmOnDeletion: { type: Boolean, default: true },
  },
  data(){
    return {
      showDeletionConfirmationDialog: false,
    }
  },
  methods:{
    confirmRoundDeletion(){
      if(this.confirmDeletion)
        this.showDeletionConfirmationDialog = true;
      else
        this.removeRound();
    },
    removeRound(){
      this.$emit('removeRound');
    },
    addRound(){
      this.$emit('addRound');
    },
  }
}
</script>

<style scoped>
  .value{
    display: flex;
    min-width: 60px;
    min-height: 40px;
    font-size:1.1rem;
    border:solid 1px black;
    border: solid 1px#ced4da;
    margin-left:5px;
    margin-right:5px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 4px;;
    background-color:c7c7c7;
    justify-content:center;
    align-items: center;
  }
</style>
